import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ACCENT_COLOR, MAIN_COLOR } from "../consts/color"

const Container = styled.section`
  max-width: 800px;
  margin: 0 auto;
  padding: 9.375rem 0;
  text-align: center;
`

const Title = styled.h1`
  font-size: 2.325em;
  font-weight: 400;
  margin-bottom: 0.625em;
`
const Alt = styled.h2`
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
  color: ${ACCENT_COLOR};
  margin-bottom: 3em;
`
const StyledLink = styled(Link)`
  font-size: 1.25em;
  text-decoration: none;
  color: ${MAIN_COLOR};
  transition: color 0.3s ease-in-out;

  &:hover {
    color: ${ACCENT_COLOR};
  }
`

const Koszonjuk = () => {
  return (
    <Layout>
      <SEO title="Köszönjük a feliratkozást!" />
      <Container>
        <Title>
          Köszönjük szépen, hogy kitöltötted ajánlatkérő űrlapunkat!
        </Title>
        <Alt>
          Hamarosan felvesszük veled a kapcsolatot e-mailben vagy telefonon,
          további egyeztetés céljából.
        </Alt>
        <StyledLink to="/">Vissza a főoldalra! &rarr;</StyledLink>
      </Container>
    </Layout>
  )
}

export default Koszonjuk
